<template>
  <div
    class="task-post"
    :class="{
      'is-note': isNote,
      'is-private': isPrivate,
      'is-agent-post': authorIsAgent
    }"
  >
    <!-- System icons -->
    <task-post-icon
      v-if="isSiteChange || isLog || isSytem"
      :size="35"
      :icon="isSiteChange ? 'random' : 'info'"
      class="task-post-avatar is-hidden-mobile"
    />

    <!-- Avatar -->
    <avatar
      v-else
      class="task-post-avatar is-hidden-mobile"
      :name="authorName"
      :avatar="authorAvatar"
      :size="35"
    />
    <slot />
  </div>
</template>

<script>
export default {
  name: "TaskEvent",
  components: {
    "task-post-icon": () => import("@shared/tasks/posts/_postIcon")
  },
  props: {
    task: {
      required: true,
      type: Object
    },
    authorId: {
      type: String,
      default: undefined
    },
    isLog: {
      type: Boolean,
      default: false
    },
    isFile: {
      type: Boolean,
      default: false
    },
    isSiteChange: {
      type: Boolean,
      default: false
    },
    isNote: {
      type: Boolean,
      default: false
    },
    isPrivate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEditing: false
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    isAuthor() {
      return this.authorId === this.userId;
    },
    authorData() {
      return this.$_.get(this.task, `participantsData.${this.authorId}`, null);
    },
    authorName() {
      if (!this.authorId || this.isLog || this.authorId === "system")
        return "System";
      return this.$_.get(this.authorData, "name");
    },
    authorAvatar() {
      if (!this.authorId || this.isLog || this.authorId === "system")
        return "/assets/images/interface/icons/013-info-circle-solid.svg";
      return this.$_.get(this.authorData, "avatarUrl");
    },
    authorIsAgent() {
      return this.$_.get(this.task, `agents`, []).includes(this.authorId);
    },
    isSytem() {
      return this.authorId === "system" || !this.authorId;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
::v-deep.task-post {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-column-gap: 1rem;
  @include mobile {
    grid-template-columns: 0 1fr;
    grid-column-gap: 0;
  }

  .task-post-wrapper {
    grid-column: 2;
    display: grid;
    grid-template-columns: 1fr 1.5rem 1.5rem;
    grid-template-rows: auto auto;
    padding: 1rem 0;
  }

  &:not(:last-child) .task-post-wrapper {
    border-bottom: 1px solid $border;
  }
  .task-post-avatar {
    grid-row: 1;
    margin-top: 1.25rem;
  }
  &.is-agent-post .task-post-meta strong {
    color: $primary;
  }
  .task-post-meta {
    grid-column: 1;
  }
  .task-post-body {
    grid-column: 1 / span 2;
    word-break: break-word;
    margin-top: 0.375em;
    p {
      white-space: pre-line;
    }
    /deep/ a {
      color: $text;
    }
  }
  .task-post-dropdown {
    justify-self: center;
    align-items: center;
  }
}
</style>
