import { render, staticRenderFns } from "./_taskEvent.vue?vue&type=template&id=1cd95f97&scoped=true"
import script from "./_taskEvent.vue?vue&type=script&lang=js"
export * from "./_taskEvent.vue?vue&type=script&lang=js"
import style0 from "./_taskEvent.vue?vue&type=style&index=0&id=1cd95f97&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd95f97",
  null
  
)

export default component.exports